import { FC } from "react";
import { Box, Button, Typography, Stack, TextField, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import { useAppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

type Form = {
  url: string;
  title: string;
  entryId: string;
  entryPw: string;
  draftFlag: boolean;
};

export const BlogNewPage: FC = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<Form>();
  const navigate = useNavigate();
  const { t } = useTranslation();  
  const { user, userId } = useAppContext();
  const submit =  async (data:Form) => {
    console.log(data) // フォームの内容が入る

    // const roomName = prompt("ルーム名を入力してください。");
    // if (roomName) {
      const newBlogRef = collection(db, "blogs");
      await addDoc(newBlogRef, {
        url: data.url,
        title: data.title,
        entryId: data.entryId,
        entryPw: data.entryPw,
        draftFlag: data.draftFlag,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        userId: userId,
      });
    // }
    navigate("/blogs");
  }
  return (
<></>
    // <Box
    //   component="form"
    //   noValidate
    //   onSubmit={handleSubmit(submit)}
    //   sx={{ p: 5,mt: 4, mx: "auto", maxWidth: "500px" ,border: '1px dashed grey' }}
    // >
    //   <Stack spacing={3}>
    //     <Stack direction="row" spacing={1} justifyContent="center">
    //       <LibraryBooksIcon />
    //       <Typography variant="h5">{t('sign_up_new')}</Typography>
    //       <LibraryBooksIcon />
    //     </Stack>

    //     <Controller
    //       name="title"
    //       control={control}
    //       defaultValue=""
    //       rules={{ required: 'require' }}
    //       render={({ field, formState: { errors } }) => (
    //         <TextField
    //           {...field}
    //           label={t('title')}
    //           fullWidth
    //           error={!!errors.title}
    //           helperText={errors.title?.message}
    //         />
    //       )}
    //     />

    //       <Controller
    //           name="url"
    //           control={control}
    //           defaultValue=""
    //           rules={{ required: 'require' }}
    //           render={({ field, formState: { errors } }) => (
    //             <TextField
    //               {...field}
    //               label="url"
    //               fullWidth
    //               placeholder="http://example.com"
    //               error={errors.url ? true : false}
    //               helperText={errors.url?.message as string}
    //             />
    //           )}
    //         />
    //       <Controller
    //           name="entryId"
    //           control={control}
    //           defaultValue=""
    //           rules={{ required: 'require' }}
    //           render={({ field, formState: { errors } }) => (
    //             <TextField
    //               {...field}
    //               label={t('poster_id')}
    //               fullWidth
    //               placeholder="admin"
    //               error={errors.entryId ? true : false}
    //               helperText={errors.entryId?.message as string}
    //             />
    //           )}
    //         />
    //          <Controller
    //             name="entryPw"
    //             control={control}
    //             defaultValue=""
    //              rules={{ required: 'require' }}
    //             render={({ field, formState: { errors } }) => (
    //               <TextField
    //                 {...field}
    //                 label="password"
    //                 fullWidth
    //                 type="password"
    //                 placeholder="password"
    //                 error={errors.entryPw ? true : false}
    //                 helperText={errors.entryPw?.message as string}
    //               />
    //             )}
    //           />
    //     {/* 他のフィールドも同様に追加 */}

    //     <FormControl component="fieldset" variant="standard">
    //       <RadioGroup row>
    //         <FormControlLabel
    //           value="0"
    //           control={<Radio />}
    //           label={t('post')}
    //           {...control.register("draftFlag")}
    //         />
    //         <FormControlLabel
    //           value="1"
    //           control={<Radio />}
    //           label={t('draft')}
    //           {...control.register("draftFlag")}
    //         />
    //       </RadioGroup>
    //       <FormHelperText>{errors.draftFlag?.message}</FormHelperText>
    //     </FormControl>

    //     <Button type="submit" variant="contained" fullWidth>
    //       {t('submit')}
    //     </Button>
    //   </Stack>
    // </Box>
  );
};

