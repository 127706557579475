import React, { FC, useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { db } from "../config/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import { Box, Stack, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ArticleCreationData {
  id: string;
  selectedImage: string;
  selectedTitle: string;
}

export const ArticleListPage: FC = () => {
  const [articles, setArticles] = useState<ArticleCreationData[]>([]);
  const { user, userId } = useAppContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (user) {
      const articleCollectionRef = collection(db, "articles");
      const q = query(articleCollectionRef, where("userId", "==", userId));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const articlesData: ArticleCreationData[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          selectedImage: doc.data().selectedImage || "images/noimage-760x460.png",
          selectedTitle: doc.data().selectedTitle,
        }));
        setArticles(articlesData);
      });
      return () => unsubscribe();
    }
  }, [userId, user]);

  // if (!articles.length) {
  //   return <div>{t('no_registered_articles')}</div>;
  // }
  if (articles.length === 0) {
    return (
      <Box component="section" sx={{ p: 5,marginBottom:5,border: '1px dashed grey' }}>
      {/* <Paper sx={{ padding: 3 }}> */}
        <Typography variant="h6" sx={{ margin: 5 ,textAlign: 'center'  }}>
          {t('created_article')}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          <Link to="/article/new">{t('create_new')}</Link>
        </Typography>
      {/* </Paper> */}
    　</Box>
    );
  }
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 4 }}>
        <Typography variant="h5">{t('created_article')}</Typography>
        <Button variant="contained" component={Link} to="/article/new">
          {t('create_new')}
        </Button>
      </Box>
      <ImageList variant="masonry" cols={isMobile ? 2 : 4} gap={8}>
        {articles.map((article) => (
          // <ImageListItem key={article.id} component={Link} to={`/article/${article.id}`}>
          <ImageListItem key={article.id} >
            <img
              srcSet={`${article.selectedImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${article.selectedImage}?w=248&fit=crop&auto=format`}
              alt={article.selectedTitle}
              loading="lazy"
            />
            <ImageListItemBar
              title={article.selectedTitle}
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${article.selectedTitle}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};
