import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./theme";
import { AppProvider } from "./context/AppContext"; 

// i18next関連のimport
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend) // バックエンドから翻訳ファイルを読み込む
  .use(LanguageDetector) // ブラウザの言語設定を検出
  .use(initReactI18next) // react-i18nextの初期化
  .init({
    fallbackLng: "ja", // 見つからない言語のフォールバック
    interpolation: {
      escapeValue: false, // Reactが既にエスケープしてくれている
    }
  }, (err, t) => {
    // 初期化が完了したらアプリケーションをレンダリング
    if (err) return console.error(err);
    renderApp();
  });
const renderApp = () => {
  const rootElement = document.getElementById("root")!;
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AppProvider> 
        <App />
        <CssBaseline />
        </AppProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
