// Step1Form.jsx
import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Avatar, Box, Button, CircularProgress, FormControl, FormLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import GirlIcon from '@mui/icons-material/Girl';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import LocationSearchingTwoToneIcon from '@mui/icons-material/LocationSearchingTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import QueryStatsTwoToneIcon from '@mui/icons-material/QueryStatsTwoTone';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import { red } from '@mui/material/colors';
import { Site, Outline,Article,Blog,ImageData,ArticleCreationData,News } from '../../types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';


// Props の型定義を追加
type Step1FormProps = {
  inputKeyword: string;
  setInputKeyword: (keyword: string) => void;
  onSubmitKeyword: () => void;
  gotoStep2: () => void;
  gotoStep3: () => void;
  suggestions:  string[]; // suggestions 配列の型を Suggestion 型の配列として追加
  setSelectedKeyword: (keyword: string) => void;
  selectedKeyword: string; // この行を追加
  isLoadingKeywords: boolean;
  isLoadingNews: boolean;
  setIsLoadingNews: (isLoading:boolean) => void;
  isLoadingSites: boolean;
  sites:  Site[];
  setSites: React.Dispatch<React.SetStateAction<Site[]>>;   
  setTarget: (target: string) => void;
  target:  string;
  role: string;
  setRole: (role: string) => void;
  selectedSites: Site[];
  setSelectedSites: (sites:Site[]) => void;
  setSelectedSitesStr: (str:string) => void;
  news:  News[];
  setNews: React.Dispatch<React.SetStateAction<News[]>>; 
  selectedNews:  News[];
  setSelectedNewsStr: (str:string) => void;
  setSelectedNews: React.Dispatch<React.SetStateAction<News[]>>;  
  currentLanguage: string; 
};

const Step1Form: React.FC<Step1FormProps> 
 = ({ inputKeyword, setInputKeyword, onSubmitKeyword ,
  suggestions,setSelectedKeyword,selectedKeyword,
  gotoStep2,gotoStep3,sites,setSites,setIsLoadingNews,
  setTarget,  isLoadingKeywords ,target,role,setRole,selectedSites, setSelectedSites, setSelectedSitesStr,news,setNews,selectedNews,setSelectedNews,setSelectedNewsStr,currentLanguage}) => {
  
  const [isLoadingSites, setIsLoadingSites] = React.useState(false);
  const [inputError, setInputError] = React.useState<string>('');

  // 選択されたサイトを管理するための新しい状態
  // const [selectedSites, setSelectedSites] = React.useState<Site[]>([]);
  const { t } = useTranslation();

  const researchNews = async () => {
    const keyword = selectedKeyword;
    // const apiKey = process.env.REACT_APP_NEWS_API_KEY;
    console.log(keyword);
    const apiKey = "0dc914d8d7cf4610b2c529513bed3b27";
    // const language = currentLanguage;
    // if(keyword.trim()){
      setIsLoadingNews(true);
      try {
        const response = await axios.get(`https://us-central1-chatapplication-with-cha-6b6f2.cloudfunctions.net/searchNewsAPI?q=${encodeURIComponent(keyword)}&apiKey=${apiKey}`);
        const newsData: News[] = response.data.articles.map((article: any) => ({
          title: article.title,
          url: article.url,
          description: article.description,
          image: article.urlToImage,
          publishedAt: article.publishedAt,
        }));
        setNews(newsData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingNews(false);
      }
    // }
  };

  // 複合キーワードの提案リストが更新されたときに、最初の項目を選択する
  useEffect(() => {
    if (suggestions.length > 0) {
      setSelectedKeyword(suggestions[0]);
    }
  }, [suggestions, setSelectedKeyword]);

  // 競合サイトのリストが更新されたときに、最初の3項目を選択する
  useEffect(() => {
    if (sites.length > 0) {
      setSelectedSites(sites.slice(0, 3)); // 最初の3項目を選択
      setSelectedSitesStr(
        selectedSites.map(site => site.title+site.snippet).join(', ')
      );
    }
  }, [sites, setSelectedSites]);
  // 競合サイトのリストが更新されたときに、最初の3項目を選択する
  useEffect(() => {
    if (sites.length > 0) {
      setSelectedNews(news.slice(0, 3)); // 最初の3項目を選択
      setSelectedNewsStr(
        selectedNews.map(news => news.title+news.description).join(', ')
      );
    }
  }, [news, setSelectedNews]);

  // チェックボックスの変更をハンドルする関数
  const handleSiteChange = (site: Site, isChecked: boolean) => {
    console.log(selectedSites);
    if (isChecked) {
      // 最大選択数を3に設定し、それ以下の場合のみ追加
      if (selectedSites.length < 3) {
        setSelectedSites([...selectedSites, site]);
      }
    } else {
      // 選択が解除された場合、リストからそのサイトを削除
      setSelectedSites(selectedSites.filter((selectedSite) => selectedSite.title !== site.title));
    }
  };
  const handleNewsChange = (news: News, isChecked: boolean) => {
    console.log(selectedNews);
    if (isChecked) {
      // 最大選択数を3に設定し、それ以下の場合のみ追加
      if (selectedNews.length < 3) {
        setSelectedNews([...selectedNews, news]);
      }
    } else {
      // 選択が解除された場合、リストからそのサイトを削除
      setSelectedNews(selectedNews.filter((selectedNews) => selectedNews.title !== news.title));
    }
  };
  
  // Step1Form 内部でのローカル状態の追加
  // const [selectedKeyword, setSelectedKeyword] = React.useState<string>();
  // 入力フィールドの変更イベントハンドラ
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    // if (e.target.value.trim() === '') {
    //   setInputError(t('enter_keywords'));
    // } else {
      setInputKeyword(e.target.value);
    // }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedKeyword(event.target.value); // 直接、選択されたキーワードを親コンポーネントの状態に設定
  };

  // キーワード提案関数
  const handleKeywordSubmit = () => {
    if (inputKeyword.trim() === '') {
      setInputError(t('enter_keywords'));
    } else {
      onSubmitKeyword();
    }
  };


  const researchCompetitorSite = async () => {
    if (selectedKeyword.trim()) {
      setIsLoadingSites(true); // ローディング開始
      try {
        console.log(selectedKeyword);
        const response = await axios.get(`https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/search-api?q=${encodeURIComponent(selectedKeyword)}&lr=${currentLanguage}`);
        const sites = response.data.items;
        console.log(response);
        console.log(sites);
        const extractedData = sites.map((site: Site) => ({
          title: site.title,
          link: site.link,
          snippet: site.snippet,
          image: site.pagemap?.cse_image?.[0]?.src, // サムネイル画像のURL
        }));
        setSites(extractedData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingSites(false); // ローディング終了
      }
    }
  };
  // const researchNews = async () => {
  //   if (selectedKeyword.trim()) {
  //     setIsLoadingNews(true); // ローディング開始
  //     try {
  //       const apiKey = '0dc914d8d7cf4610b2c529513bed3b27'; // 実際のAPIキーを使用
  //       const response = await axios.get(`https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/news-api?q=${encodeURIComponent(selectedKeyword)}&apiKey=${apiKey}`);
  //       const newsData = response.data.articles;
  //       console.log(newsData);
  //       const extractedData = newsData.map((article: any) => ({
  //         title: article.title,
  //         url: article.url,
  //         description: article.description,
  //         image: article.urlToImage, // サムネイル画像のURL
  //         publishedAt: article.publishedAt,
  //       }));
  //       setNews(extractedData);
  //       setSelectedNews(extractedData); // ここで全ニュースを選択状態に設定
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setIsLoadingNews(false); // ローディング終了
  //     }
  //   }
  // }

  const research = async () =>{
    setSites([]); // 関連サイトリストをクリア
    setNews([]); // 関連サイトリストをクリア
    researchNews();
    console.log(news);
    researchCompetitorSite();

  } 
   function setShowErrorModal(arg0: boolean): void {
     throw new Error('Function not implemented.');
   }

  return (
    <>
  <Grid container spacing={1}
      direction="row"
      justifyContent="center"
      // alignItems="center"
      >
    <Grid item xs={12} lg={12}>
    {/* <Container> */}
    <FormGroup>
    <Box component="section" sx={{ p: 1,marginBottom:5,border: '1px dashed grey' }}>
    <Stack  my={2} direction="row" justifyContent="start" spacing={1}>
        <ContentPasteSearchTwoToneIcon />
        <Typography variant="h5">{t('select_keywords')}</Typography>
        </Stack>
        <TextField 
        variant="outlined" 
        margin="normal"
        fullWidth 
        label={t('keyword')}
        value={inputKeyword}
        // onChange={(e) => setInputKeyword(e.target.value)} // この行を追加
        onChange={handleInputChange}
        error={inputError !== ''}
        helperText={inputError}
        />
     {/* <Stack my={2} direction="row" justifyContent="start" spacing={2} sx={{ minWidth: 0 }} alignItems="center" >
        <PersonSearchTwoToneIcon />
        <Typography variant="h6">{t('readers')}</Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={t('target_user')}
          value={target} // 初期値を設定
          onChange={(e) => setTarget(e.target.value)}
          sx={{
            alignItems: 'center', // この行を追加
          }}
        >
          <FormControlLabel value={t('all')} control={<Radio />} label={t('all')}  sx={{ alignItems: 'center' }}/>
          <FormControlLabel value={t('male')} control={<Radio />} label={t('male')}  sx={{ alignItems: 'center' }}/>
          <FormControlLabel value={t('female')} control={<Radio />} label={t('female')}  sx={{ alignItems: 'center' }}/>
          {/* <FormControlLabel value="30代男性" control={<Radio />} label="30代男性" /> */}
        {/* </RadioGroup>
      </Stack> */} 
      <Stack my={2} direction="row" justifyContent="start" spacing={1} alignItems="center">
        <Typography variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },width:"50px" }}>
          {t('readers')}
        </Typography>
        <RadioGroup
          row
          name="target"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{
            width: '100%', // 幅を100%に設定してレスポンシブに対応
          }}
        >
          <FormControlLabel value={t('all')} control={<Radio />} label={<Typography sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>{t('all')}</Typography>} />
          <FormControlLabel value={t('male')} control={<Radio />} label={<Typography sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>{t('male')}</Typography>} />
          <FormControlLabel value={t('female')} control={<Radio />} label={<Typography sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>{t('female')}</Typography>} />        
        </RadioGroup>
      </Stack>
      <Stack my={2} direction="row" justifyContent="start" spacing={2} alignItems="center">

        <Typography variant="h6" sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem',width:"80px" } }}>
          {t('writers')}
        </Typography>
        <RadioGroup
          row
          name="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          sx={{
            width: '100%', // 幅を100%に設定してレスポンシブに対応
          }}
        >
          <FormControlLabel value={t('expert')} control={<Radio />} label={<Typography sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>{t('expert')}</Typography>} />
          <FormControlLabel value={t('consumer')} control={<Radio />} label={<Typography sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>{t('consumer')}</Typography>} />
        </RadioGroup>
      </Stack>

      </Box>
        </FormGroup>
        {/* <TextField variant="outlined" margin="normal" fullWidth label="ターゲット" /> */}
        {/* <Button variant="contained" color="primary" fullWidth  onClick={onSubmitKeyword}> */}
        <Button variant="contained" color="primary" fullWidth onClick={handleKeywordSubmit}>
          <ArrowDownwardIcon/>
          {t('compound_keywords_research')}
          <ArrowDownwardIcon/>
        </Button>
       

          { isLoadingKeywords && (
                <Box component="section" sx={{ my:20,alignItems: 'center',textAlign:'center'}} ><CircularProgress /></Box>
          )}
      { suggestions.length > 0 && (
        <>


        <Box component="section" sx={{  p: 1,marginBottom:5,marginTop:5,border: '1px dashed grey',width: 1 }}>
        <Stack  my={2} direction="row" justifyContent="start" spacing={1}>
          <QueryStatsTwoToneIcon />   
          <Typography variant="h5"  my={2} > {t('compound_keywords_list')}</Typography>
        </Stack>
        <RadioGroup aria-label="selectedKeyword" name="selectedKeyword" row value={selectedKeyword} onChange={handleChange} sx={{p:0.5}}>
        {/* <Box  sx={{ p: 5,border: '1px dashed grey' }}> */}
        {suggestions.map((suggestion, index) => (
          <Box 
          sx={{ 
            // p: 0.1, // パディングを小さく調整
            m: 0.1, // マージンを追加して周囲のスペースを確保（必要に応じて）
            border: '1px dashed grey', 
            background: index < 10 ? '#e57373' : index < 20 ? '#ffb74d' : '#fff176',
            borderRadius: '5px', // ここで角丸設定を追加
          }}
        >
          <FormControlLabel
            control={<Radio />}
            label={
              <Typography variant="button">{suggestion}</Typography> // 文字サイズを小さくする
            }
            value={suggestion}
            sx={{ fontSizeAdjust:"inherit" }} // ここでFormControlLabelのマージンを調整（必要に応じて）
          />
          </Box>
          ))}
        {/* </Box> */}
        </RadioGroup>
        </Box>
        { selectedKeyword.length > 0 && (
        // <Button variant="contained" color="primary" fullWidth  onClick={gotoStep2}>
        //   タイトルを提案
        // </Button>
        <Button variant="contained" color="primary" fullWidth  onClick={research}>
          <ArrowDownwardIcon/>
          {t('competitor_site_research')}
        <ArrowDownwardIcon/>
      </Button>
      )}
        </>
      )}
        
    {/* </Container> */}
    </Grid>
    <Grid item  xs={12}  lg={12}>
      { isLoadingSites && (
         <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            <CircularProgress />
          </Box>
      )}
      { (news.length > 0 || sites.length > 0)&& (
      <Box component="section" sx={{  p: 1,marginBottom:1,marginTop:1,border: '1px dashed grey',width: 1 }}>
      <FormControl component="fieldset">
      <Stack  my={2} direction="row" justifyContent="start" spacing={2}>
        <ScreenSearchDesktopTwoToneIcon />
        <Typography variant="h5" gutterBottom>Related News</Typography>
      </Stack>
{/* <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>

    {news.map((newsItem, index) => (
      <ListItem 
        sx={{ 
          display: "flex", 
          alignItems: "flex-start", 
          paddingY: 2, 
          bgcolor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.06)' : 'rgba(0, 0, 0, 0.12)', // 背景色をより区別しやすくする
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)', // 区切り線を追加
          '&:last-child': {
            border: 0,
          }
        }} 
        key={index}
      >
        <ListItemAvatar>
          <Avatar alt={newsItem.title} src={newsItem.image} sx={{ width: 56, height: 56, marginRight: 2 }} />
        </ListItemAvatar>
        
        <ListItemText
          primary={<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{newsItem.title}</Typography>}
          secondary={
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                style={{ display: 'block', marginBottom: '8px' }}
              >
                {newsItem.description}
              </Typography>
              <Typography 
                variant="body2"
                component="a"
                href={newsItem.url}
                target="_blank"
                style={{ textDecoration: 'none', color: '#1976d2' }}
              >
                Go
              </Typography>
            </>
          }
        />

    <Checkbox
      onChange={(e) => handleNewsChange(newsItem, e.target.checked)}
      checked={selectedNews.some((selectedNews) => selectedNews.title === newsItem.title)}
      sx={{ marginLeft: 'auto' }}
    />
      </ListItem>
    ))}

</List> */}
      <List sx={{ 
    width: '100%', 
    bgcolor: 'background.paper', 
    overflowX: 'auto', // 水平スクロールを許可
    borderRadius: '10px', 
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)' 
  }}>
    {news.map((newsItem, index) => (
      <ListItem 
        key={index}
        sx={{ 
          display: "flex", 
          alignItems: "flex-start", 
          paddingY: 1, 
          bgcolor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.06)' : 'rgba(0, 0, 0, 0.12)', 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
          '&:last-child': {
            border: 0,
          },
          flexDirection: { xs: 'column', sm: 'row' } // スマートフォンでは縦に並べる
        }}
      >
        <ListItemAvatar>
          <Avatar alt={newsItem.title} src={newsItem.image} sx={{ width: 56, height: 56, marginRight: 2 }} />
        </ListItemAvatar>
        
        <ListItemText
          primary={<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{newsItem.title}</Typography>}
          secondary={
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                style={{ display: 'block', marginBottom: '1px' }}
              >
                {newsItem.description}
              </Typography>
              {/* <Typography 
                variant="body2"
                component="a"
                href={newsItem.url}
                target="_blank"
                style={{ textDecoration: 'none', color: '#1976d2' }}
              >
                Go
              </Typography> */}
            </>
          }
        />
        <Checkbox
      onChange={(e) => handleNewsChange(newsItem, e.target.checked)}
      checked={selectedNews.some((selectedNews) => selectedNews.title === newsItem.title)}
          sx={{ marginLeft: 'auto' }}
        />
      </ListItem>
    ))}
  </List>
      <Stack  my={2} direction="row" justifyContent="start" spacing={1}>
        <ScreenSearchDesktopTwoToneIcon />
        <Typography variant="h5" gutterBottom>{t('top_search_competitor_sites')}</Typography>
      </Stack>
      <List sx={{ 
    width: '100%', 
    bgcolor: 'background.paper', 
    overflowX: 'auto', // 水平スクロールを許可
    borderRadius: '10px', 
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)' 
  }}>
    {sites.map((site, index) => (
      <ListItem 
        key={index}
        sx={{ 
          display: "flex", 
          alignItems: "flex-start", 
          paddingY: 2, 
          bgcolor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.06)' : 'rgba(0, 0, 0, 0.12)', 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
          '&:last-child': {
            border: 0,
          },
          flexDirection: { xs: 'column', sm: 'row' } // スマートフォンでは縦に並べる
        }}
      >
        <ListItemAvatar>
          <Avatar alt={site.title} src={site.image} sx={{ width: 40, height: 40, marginRight: 2 }} />
        </ListItemAvatar>
        
        <ListItemText
          primary={<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{site.title}</Typography>}
          secondary={
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                component="span"
                style={{ display: 'block', marginBottom: '1px' }}
              >
                {site.snippet}
              </Typography>
              {/* <Typography 
                variant="body2"
                component="a"
                href={site.link}
                target="_blank"
                style={{ textDecoration: 'none', color: '#1976d2' }}
              >
                Go
              </Typography> */}
            </>
          }
        />
        <Checkbox
          onChange={(e) => handleSiteChange(site, e.target.checked)}
          checked={selectedSites.some((selectedSite) => selectedSite.title === site.title)}
          sx={{ marginLeft: 'auto' }}
        />
      </ListItem>
    ))}
  </List>

      </FormControl>
      {/* <Button variant="contained" color="primary" fullWidth  onClick={gotoStep2} sx={{p:1,margin:1}}>
      <ArrowDownwardIcon/> {t('suggest_title_step2')}<ArrowDownwardIcon/>
      </Button> */}
      <Button variant="contained" color="secondary" fullWidth  onClick={gotoStep3} sx={{p:1,margin:1}}>
      <ArrowDownwardIcon/>{t('suggest_article_step3')}<ArrowDownwardIcon/>
      </Button>
      </Box>
      
       )} 
    </Grid>
  </Grid>
    </>
  );
}

export default Step1Form;
