import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Site, Plan, PlansData } from '../types';
import { HttpsCallableResult, getFunctions, httpsCallable } from "firebase/functions";
import { useAppContext } from "../context/AppContext";

// Stripeの公開可能キーを設定
// const stripePromise = loadStripe("pk_test_...");

export const SettingPlanPage: FC = () => {
  const [currentPlan, setCurrentPlan] = useState<string | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [currentPlanId, setCurrentPlanId] = useState('');
  const [loading, setLoading] = useState(true);
  const { user, userId } = useAppContext();
  const functions = getFunctions(); // Firebase Functionsのインスタンスを取得
  const deleteCustomer = httpsCallable(functions, 'deleteStripeCustomer');
  console.log(userId);
  console.log(user);
  // const stripePromise = loadStripe("YOUR_PUBLIC_KEY_HERE");

  const handleCheckout = async (planId: string) => {
    try {
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      const response: HttpsCallableResult<any> = await createCheckoutSession({ priceId: planId, successUrl: 'https://writer.zero-one.llc/settings/plan', cancelUrl: 'https://writer.zero-one.llc/settings/plan' });
      const sessionId: string = response.data?.sessionId as string;
      
      const stripe = await loadStripe('pk_live_51ONAIsDTPn6cLGBh1DMcwosf973m2uCBMU1HpPmEZeIQFu5nmOUNy6KJjM8qTiYUlSrvnOSbhx6OU0maXMJjIReM00xLR27vUm');
      if(stripe)await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      alert('支払いのセッションの作成に失敗しました。');
    }
  };
  // const handleCheckout = async (planId: string) => {
  //   try {
  //     const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
  //     const response = await createCheckoutSession({ priceId: planId, successUrl: '成功時のURL', cancelUrl: 'キャンセル時のURL' });
  //     const sessionId: string = response.data.sessionId;
      

      
  //     // Stripe Checkout セッションを開始
  //     const stripe = await loadStripe('pk_live_51ONAIsDTPn6cLGBh1DMcwosf973m2uCBMU1HpPmEZeIQFu5nmOUNy6KJjM8qTiYUlSrvnOSbhx6OU0maXMJjIReM00xLR27vUm');
  //     if(stripe){
  //       await stripe.redirectToCheckout({ sessionId });
  //     }
  //   } catch (error) {
  //     console.error('Failed to create checkout session:', error);
  //     alert('支払いのセッションの作成に失敗しました。');
  //   }
  // };


  const handleCancelSubscription = async () => {
    if (!userId) {
      alert("ユーザーIDが見つかりません。");
      return;
    }
  
    const deleteCustomer = httpsCallable(functions, 'deleteStripeCustomer');
  
    setLoading(true); // 処理中にローディング状態にする
    try {
      await deleteCustomer({ userId });
      alert("解約処理が完了しました。");
      // 解約後の処理（プラン一覧の再取得など）
    } catch (error) {
      console.error("解約処理に失敗しました。", error);
      alert("解約処理に失敗しました。");
    }
    setLoading(false);
  };



  
  useEffect(() => {

    // fetchPlansAndCurrentSubscription();
    console.log(userId);
    console.log(user);
    const functions = getFunctions(); // Firebase Functionsのインスタンスを取得
    const getPlansAndCurrentSubscription = httpsCallable(functions, 'getPlansAndCurrentSubscription');

    getPlansAndCurrentSubscription().then((result) => {
      const data = result.data as PlansData;
      // const { plans, currentPlanId } = result.data;
      console.log(data);
      setPlans(data.plans);
      setCurrentPlanId(data.currentPlanId);
      setLoading(false);
    }).catch((error) => {
      console.error("Failed to fetch plans and current subscription:", error);
      setLoading(false);
    });

  }, [userId]);

  useEffect(() => {
    // Stripeのスクリプトを動的にロード
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // コンポーネントのアンマウント時にスクリプトを削除
      document.body.removeChild(script);
    };
  }, []);
  if (loading) return <CircularProgress />;
  return (
    <>
    <Button
  variant="contained"
  color="secondary"
  onClick={handleCancelSubscription}
  disabled={!currentPlanId} // 現在のプランIDがない場合はボタンを無効化
>
  解約する
</Button>
    
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>プラン名</TableCell>
            <TableCell align="right">価格</TableCell>
            <TableCell>説明</TableCell>
            <TableCell>手続き</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.map((plan) => (
            <TableRow key={plan.id} selected={plan.id === currentPlanId}>
              <TableCell component="th" scope="row">{plan.productName}</TableCell>
              <TableCell align="right">{plan.amount}</TableCell>
              <TableCell>
                <Typography variant="body2">{plan.description}</Typography>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCheckout(plan.id)}
                >
                  登録する
                </Button>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer><div>
        {/* Stripeの料金比較表カスタムタグ */}
        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1OjQZtDTPn6cLGBhGFoWMrgG"
          publishable-key="pk_live_51ONAIsDTPn6cLGBh1DMcwosf973m2uCBMU1HpPmEZeIQFu5nmOUNy6KJjM8qTiYUlSrvnOSbhx6OU0maXMJjIReM00xLR27vUm" selected>
        </stripe-pricing-table> */}
      </div></>
  );


};


