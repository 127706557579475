import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography, FormGroup, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, Box, CircularProgress, List, ListItem, ListItemText, Stack } from '@mui/material';
import { styled } from '@mui/system';
import SavedSearchTwoToneIcon from '@mui/icons-material/SavedSearchTwoTone';
import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';
import { Site } from '../../types';
import { useTranslation } from 'react-i18next';
type Outline = {
  items: string[];
}
// Props の型定義を更新
type Step2FormProps = {
  selectedKeyword: string; // 選択されたキーワードの配列
  category:string;
  // onSubmitSelecteSites: () => void;
  suggestTitles: string[];
  selectedTitle: string; // この行を追加
  onSubmitSelectedTitle:  (keyword: string) => void;
  setSelectedTitle: (selectedTitle: string) => void; // この行を追加
  // setSelectedOutline: (selectedOutline: string) => void; // この行を追加
  isLoadingTitles: boolean;
  isLoadingOutlines: boolean;
  selectedOutline:string | null;
  setSelectedOutline: (selectedOutline: string | null) => void;
  onSubmitSelectedOutline: (outline: Outline) => void;
  suggestOutlines: Outline[]; // この行を追加
  selectedSites: Site[];
  selectedSitesStr: string;
};

const Step2Form: React.FC<Step2FormProps> = ({ 
  selectedKeyword,onSubmitSelectedTitle,suggestTitles,
   selectedTitle,setSelectedTitle,
   suggestOutlines,isLoadingTitles,isLoadingOutlines,selectedOutline, 
   setSelectedOutline, onSubmitSelectedOutline
  ,selectedSites,selectedSitesStr}) => {
  const { t } = useTranslation();
  // テキストフィールドの値を変更するための新しいハンドラ
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTitle(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTitle(event.target.value);
  };
  // 文書を提案するハンドラ
  const handleSuggestArticle = (outline:Outline) => {

    console.log(outline);
    // if (outline) {
      const outlineString = outline.items.join('\n');
      console.log(outlineString);
      setSelectedOutline(outlineString);
      console.log(selectedOutline);
      onSubmitSelectedOutline(outline);
    // // }
  };
  const handleSubmit = () => {
    onSubmitSelectedTitle(selectedTitle);
  };


  useEffect(() => {
    if (suggestTitles.length > 0 && !selectedTitle) {
      setSelectedTitle(suggestTitles[0]);
    }
  }, [suggestTitles, selectedTitle, setSelectedTitle]);
  return (
    <>
    <Grid container spacing={4} direction="row" justifyContent="center" >
      <Grid item xs={12} lg={6}>
        <Box sx={{ p: 4, border: '1px dashed grey' , borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
        <Stack  my={2} direction="row" justifyContent="start" spacing={2}>
        <SavedSearchTwoToneIcon />     
          <Typography variant="h5" gutterBottom>{t('ai_title_suggestion')}</Typography>
        </Stack>
        <Box sx={{p:2,bgcolor:""}}>
          <Typography variant="body1" gutterBottom>
          1.{t('keywords')}「{selectedKeyword}」2.References「{selectedSites.map(site => site.title).join('/')}」
          </Typography>
          </Box>
          {isLoadingTitles ? (
            <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            <CircularProgress />
            </Box>
          ) : (
            <RadioGroup aria-label="suggested-titles" name="suggested-titles" value={selectedTitle} onChange={handleChange}>
              {suggestTitles.map((suggestTitle, index) => (
                <FormControlLabel key={index} value={suggestTitle} control={<Radio />} label={suggestTitle} />
              ))}
            </RadioGroup>
          )}
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="タイトル"
            value={selectedTitle}
            onChange={handleTextFieldChange}
            margin="normal"
          />
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} sx={{ mt: 2 }}>
          <ManageSearchTwoToneIcon /> 
            {t('outline_suggestion')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={{ p: 4, border: '1px dashed grey' , borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
        <Stack  my={2} direction="row" justifyContent="start" spacing={2}>
        <ManageSearchTwoToneIcon />     
          <Typography variant="h5" gutterBottom>{t('outline_suggestion')}</Typography>
        </Stack>
          {isLoadingOutlines ? (
            <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            <CircularProgress />
            </Box>
          ) : (
            suggestOutlines.map((outline, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <List dense>
                  {outline.items.map((item, itemIndex) => (
                    <ListItem key={itemIndex}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
                <Button variant="contained" color="primary" fullWidth onClick={() => handleSuggestArticle(outline)}>
                {t('propose_article_with_this_outline')}
                </Button>
              </Box>
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  </>
  
  );
}

export default Step2Form;
