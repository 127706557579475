import { Site, Outline,Article,Blog,ImageData, ResearchSite } from '../types';
import OpenAI from "openai";
import { serverTimestamp } from "firebase/firestore"; 
import axios from 'axios';
import { marked } from 'marked';


const Anthropic = require("@anthropic-ai/sdk");

export function extractJsonFromTextEnhanced(text:string) {
  let jsonStr = text;
  const startMarker = "```json";
  const endMarker = "```";

  // マーカーが存在する場合、その範囲のテキストを使用
  if (text.includes(startMarker) && text.includes(endMarker)) {
      const start = text.indexOf(startMarker) + startMarker.length;
      const end = text.indexOf(endMarker, start);
      jsonStr = text.slice(start, end).trim();
  }

  // JSONとしてパースを試みる
  try {
      return JSON.parse(jsonStr);
  } catch (e) {
      console.error("Error parsing JSON:", e);
      return null;  // JSONとしてパースできない場合はnullを返す
  }
}


export function fillTemplate(template: string, values: any): string {
  let result = template.replace(/\$\{(\w+)\}/g, (match, key) => {
    return values.hasOwnProperty(key) ? values[key] : '';
  });

  result = result.split('。').filter(sentence => {
    return !/\$\{.*?\}/.test(sentence);
  }).join('。');
  result = result.replace(/。+/g, '。');

  return result.trim();
}

export const fetchFromAI = async (template: string, values: any) => {
  const filledTemplate = fillTemplate(template, values);
  console.log(filledTemplate);
  console.log(values.ai)
  // AIの選択に応じたAPI呼び出し
  switch (values.ai) {
    case 'gpt-3.5-turbo':
      // OpenAI GPT-3.5 Turboの使用
      return fetchFromOpenAI(filledTemplate,values);
    case 'gpt-4':
      // OpenAI GPT-4の使用
      return fetchFromOpenAI(filledTemplate,values);
    case 'claude-sonnet':
      // AnthropicのClaude-3 Opusの使用
      return fetchFromAnthropic(filledTemplate,values);
    case 'claude-opus':
      // AnthropicのClaude-3 Opusの使用
      return fetchFromAnthropic(filledTemplate,values);
    default:
      throw new Error('Unsupported AI model selected');
  }
};


export const fetchFromOpenAI = async (template: string, values: any) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });
  console.log(values);
  console.log(values.selectedSites);
  console.log(values.selectedNews);
  
  try {
    // 参考テキストとして使用するresearchSitesのmarkdownTextをメッセージとしてセットアップ
    let messages = values.researchSites.map((site:ResearchSite) => ({
      role: "user", // または "user", ユースケースに応じて選択
      content: `盛り込む内容: ${site.markdownText}`,
    }));
    console.log(messages);
    // templateを基にしたメインのクエリを作成
    const inputMessage = fillTemplate(template, values);
    // メインのクエリをメッセージリストに追加
    messages.push({ role: "user", content: inputMessage });

    // 修正: initialMessagesではなく、正しくはmessagesを送信する
    const gpt3Response = await openai.chat.completions.create({
      model: "gpt-4-0125-preview", // 利用するモデル
      messages: messages, // 修正された部分
    });

    console.log(gpt3Response);
    return gpt3Response.choices[0].message.content;
  } catch (error) {
    console.error("Error fetching from OpenAI:", error);
    throw error;
  }
};





export const generateArticleId = (userId: string, title: string): string => {
  // ドキュメントIDに使用できない文字を置換または削除
  const safeTitle = title.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').toLowerCase();
  return `${userId}-${safeTitle}`;
};

// export const createHtmlWithImage = (article:Article, imageUrl:string) => {
//     // 目次を生成
//     const toc = article.body.sections.map((section, index) => 
//       `<li><a href="#section-${index}">${section.h2}</a></li>`
//     ).join('');
  
/**
 * Fills a template with values and sends it to Anthropic's API.
 * @param {string} template The template string.
 * @param {object} values The values to fill in the template.
 * @returns {Promise<string>} The response from the AI.
 */
export const fetchFromAnthropic = async (template:string,values:any) => {
  // Initialize the Anthropic SDK with your API key
  // const anthropic = new Anthropic({
  //   apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY
  // });
  console.log(values);
  try {
    // Assuming fillTemplate is a function you've defined to fill your template string with values
    const filledTemplate = fillTemplate(template, values);
    console.log(filledTemplate);

    const  apiEndpoint = "https://us-central1-chatapplication-with-cha-6b6f2.cloudfunctions.net/fetchFromAnthropic";
    // if (filledTemplate.trim()) {

        const response = await axios.post(apiEndpoint, {
          messages: filledTemplate,
          model: values.ai
        }); 
        console.log(response);     
        const answer = response.data.content[0].text;
        console.log(answer);

        return answer;
  } catch (error) {
    console.error("Error fetching from Anthropic:", error);
    throw error;
  }
};

export const createHtmlWithImage = (article:any, images:any) => {

  console.log(article);
  console.log(images);
    // 画像があれば、Markdown 形式で記事の先頭に画像を挿入
  const imageMarkdown = images.length > 0
    ? `![${images[0].tags}](${images[0].largeImageURL})\n\n`
    : '';
  // 記事の本文（Markdown形式）をHTMLに変換
  // const bodyHtml = marked(article.body);
    // 画像を含む完全な記事テキストを作成
  const completeArticle = imageMarkdown + article;
  console.log(completeArticle);
  // 記事からタイトル（Markdownの最初の '#' 部分）を抽出
  // const titleMatch = completeArticle.match(/^# (.+)/);
  // console.log(titleMatch);
  // const title = titleMatch ? titleMatch[1] : 'No Title';

  const bodyHtml = marked(completeArticle);
  console.log(bodyHtml);
    // 完全なHTMLを生成
  const html = `
    <article>
      <div>${bodyHtml}</div>
    </article>
  `;
  // トップ画像があればHTMLを生成、なければ空文字
  // const topImageHtml = images.length > 0
  //   ? `<img src="${images[0].largeImageURL}" alt="${images[0].tags}" style="max-width:100%; height: auto;" />`
  //   : '';

  // // 完全なHTMLを生成
  // const html = `
  //   <article>
  //     <header>${topImageHtml}</header>
  //     <div>${bodyHtml}</div>
  //   </article>
  // `;

  return { html };
};

// export const createHtmlWithImage = (article: Article, images: ImageData[]) => {
//   // 目次を生成
//   const toc = article.body.sections.map((section, index) => 
//     `<li><a href="#section-${index}">${section.h2}</a></li>`
//   ).join('');

//   // トップの画像が存在する場合に限りHTMLを生成
//   const topImageHtml = images.length > 0 
//     ? `<img src="${images[0].largeImageURL}" alt="${images[0].tags}" style="max-width:100%; height: auto;" />` 
//     : '';

//   // 各セクションと見出しの上に画像が存在する場合に限りHTMLを挿入
//   const sectionsHtml = article.body.sections.map((section, index) => {
//     const imageIndex = index + 1; // トップ画像を除いたインデックス
//     const sectionImageHtml = images.length > imageIndex
//       ? `<img src="${images[imageIndex].largeImageURL}" alt="${images[imageIndex].tags}" style="max-width:100%;; height: auto;" />`
//       : '';
//     return `
//       <section id="section-${index}">
//         ${sectionImageHtml}
//         <h2>${section.h2}</h2>
//         ${section.paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('')}
//         ${(section.subsections || []).map(subsection => `
//           <h3>${subsection.h3}</h3>
//           <p>${subsection.content}</p>
//         `).join('')}
//       </section>
//     `;
//   }).join('');

//   // 完全なHTMLを生成
//   const html = `
//     <article>
//       <header>
//         <h1>${article.title}</h1>
//         ${topImageHtml}
//       </header>
//       <aside>
//         <h2>目次</h2>
//         <ul>${toc}</ul>
//       </aside>
//       ${sectionsHtml}

//         <h2>${article.body.conclusion.h2}</h2>
//         <p>${article.body.conclusion.paragraph}</p>

//     </article>
//   `;

//   return html;
// };
