import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import i18n from 'i18next';

export default function App() {
  let { lang } = useParams(); // URLから言語コードを取得

  // useEffect(() => {
  //   if (lang) {
  //     i18n.changeLanguage(lang); // 言語を変更
  //   }
  // }, [lang]);

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}
