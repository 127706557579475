
import {
  Timestamp,
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { ChangeEvent, useEffect, useState } from "react";
import { memo, FC } from "react";

import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import { Avatar, Box, Button, FormControlLabel, ListItem, Radio, RadioGroup, Stack, Tab, Typography, styled } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import { useAppContext } from "../context/AppContext";
import { BlogCard } from "../components/BlogList/BlogCard";
import { auth, db } from "../config/firebase";
import image from "next/image";
import { useTranslation } from "react-i18next";
// import { useAllUsers } from "../hooks/useAllUsers";
type Blog = {
    id: string
    url: string
    title: string
    entryId: string
    entryPw: string
    draftFlag: boolean
  }
  const CustomRadio = styled(Radio)({
    color: 'white', // 未選択状態の色
    '&.Mui-checked': {
      color: 'white', // 選択状態の色
    },
  });

  export const BlogListPage: FC = memo(() => {
    const { t } = useTranslation();   
    const { user, userId } = useAppContext();
    console.log("xxx",userId);
    // const [value, setValue] = useState("1"); // タブ番号
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [selectedBlog, setSelectedBlog] = useState<Blog>();
  //   const { getAllBlogs, blogs } = useAllBlogs(); // カスタムフック
  

  useEffect(() => {
    // ブログデータ取得
    if (user) {
      const fetchBlogs = async () => {
        const blogCollectionRef = collection(db, "blogs");
        const q = query(
          blogCollectionRef,
          where("userId", "==", userId),
          orderBy("createdAt")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newBlogs: Blog[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            url: doc.data().url,
            title: doc.data().title,
            entryId: doc.data().entryId,
            entryPw: doc.data().entryPw,
            draftFlag: doc.data().draftFlag,
            createdAt: doc.data().createdAt,
          }));
          setBlogs(newBlogs);
        });
  
        return () => {
          unsubscribe();
        };
      };
  
      fetchBlogs();
    }
  }, [userId, user]); // 依存配列
  
  
    function handleBlogChange(event: ChangeEvent<HTMLInputElement>, value: string): void {
      throw new Error("Function not implemented.");
    }
    if (blogs.length === 0) {
      return (
        <Box component="section" sx={{ p: 5,marginBottom:5,border: '1px dashed grey' }}>
        {/* <Paper sx={{ padding: 3 }}> */}
          <Typography variant="h6" sx={{ margin: 5 ,textAlign: 'center'  }}>
            {t('no_registered_blogs')}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            <Link to="/blog/new">{t('register_your_blog')}</Link>
          </Typography>
        {/* </Paper> */}
      　</Box>
      );
    }
    return (
      <Paper
        sx={{
          width: `80%`,
          overflow: "auto",
          m: 1,
          borderRadius: 5,
          boxShadow: 10,
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 4 }}>

          <Typography variant="h5">{t('sign_up_new')}</Typography>

          <Button variant="contained" component={Link} to="/blog/new">
          {t('create_new')}
          </Button>
        </Box>
              <MenuList sx={{justifyContent:"center"}}>

              {blogs.map((blog:Blog ) => (
              <ListItem key={blog.id} sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
              <FormControlLabel
                control={<CustomRadio value={blog.id} />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Avatar
                      alt={blog.title}
                      src={`http://capture.heartrails.com/150x100/cool?${encodeURIComponent(blog.url)}`}
                      sx={{ width: 56, height: 56, marginRight: 2 }}
                    />
                    <Box>
                      <Typography variant="subtitle1">{blog.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {blog.url}
                      </Typography>
                      <Typography variant="body2" sx={{ color: blog.draftFlag ? 'error.main' : 'success.main' }}>
                        {blog.draftFlag ? t('draft') : t('post')}
                      </Typography>
                    </Box>
                  </Box>
                }
                sx={{ marginRight: 'auto', '& .MuiSvgIcon-root': { fontSize: '1.2rem' } }}
              />
            </ListItem>
              ))}

              </MenuList>
        </Box>
      </Paper>
    );
  });

// import React, { FC, memo, useEffect, useState } from 'react';
// import { Avatar, Card, CardActionArea, CardContent, CardMedia, Typography, Box } from '@mui/material';
// import { Timestamp, collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
// import { Blog } from '../types';
// import { useAppContext } from '../context/AppContext';
// import { db } from '../config/firebase';



// // ブログリストを表示するコンポーネント
// // export const BlogListPage = ({ blogs }: { blogs: Blog[] }) => {
// export const BlogListPage: FC = memo(() => {
//     const [blogs, setBlogs] = useState<Blog[]>([]);
//     const [selectedBlog, setSelectedBlog] = useState<Blog>();
//     const { user, userId } = useAppContext();
//     useEffect(() => {
//     // ブログデータ取得
//     if (user) {
//       const fetchBlogs = async () => {
//         const blogCollectionRef = collection(db, "blogs");
//         const q = query(
//           blogCollectionRef,
//           where("userId", "==", userId),
//           orderBy("createdAt")
//         );
//         const unsubscribe = onSnapshot(q, (snapshot) => {
//           const newBlogs: Blog[] = snapshot.docs.map((doc) => ({
//             id: doc.id,
//             url: doc.data().url,
//             title: doc.data().title,
//             entryId: doc.data().entryId,
//             entryPw: doc.data().entryPw,
//             draftFlag: doc.data().draftFlag,
//             createdAt: doc.data().createdAt,
//           }));
//           setBlogs(newBlogs);
//         });
  
//         return () => {
//           unsubscribe();
//         };
//       };
  
//       fetchBlogs();
//     }
//   }, [userId, user]); // 依存配列
//   return (
//     <Box>
//       {blogs.map((blog) => (
//         <Card key={blog.id} sx={{ display: 'flex', m: 1, alignItems: 'center' }}>
//           <CardActionArea sx={{ display: 'flex', flexGrow: 1 }}>
//             {/* サイトキャプチャのアバター */}
//             <Avatar 
//               alt={blog.title}
//               src={`http://capture.heartrails.com/150x100/cool?${encodeURIComponent(blog.url)}`}
//               sx={{ width: 56, height: 56, m:2 }}
//             />
//             <CardContent sx={{ flexGrow: 1 }}>
//               <Typography gutterBottom variant="h5" component="div">
//                 {blog.title}
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 {blog.url}
//               </Typography>
//               {/* 登録日 */}
//               {/* <Typography variant="body2" color="text.secondary">
//                 登録日: {blog.createdAt.toDate().toLocaleDateString()}
//               </Typography> */}
//               {/* 下書き状態 */}
//               <Typography variant="body2" color={blog.draftFlag ? "error" : "success"}>
//                 {blog.draftFlag ? "下書き" : "公開済み"}
//               </Typography>
//             </CardContent>
//           </CardActionArea>
//         </Card>
//       ))}
//     </Box>
//   );
// });

// export default BlogList;
