// Step3Form.jsx
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Backdrop, Box, Button, ButtonGroup, CircularProgress, FormControl, ImageList, ImageListItem, InputLabel, Link, MenuItem, Modal, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography, withStyles } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import  '../../App.css';
import useFetchImages from '../../hooks/useFetchImages'; // パスはプロジェクト構成に応じて調整してください

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useAppContext } from "../../context/AppContext";
import { auth, db } from "../../config/firebase";
import { createHtmlWithImage } from '../../utils/articleUtils';
import { postArticle } from '../../services/articleServices';
import { Site, Outline,Article,Blog,ImageData,ArticleCreationData } from '../../types';
import { useTranslation } from 'react-i18next';

const CustomRadio = styled(Radio)({
  color: 'white', // 未選択状態の色
  '&.Mui-checked': {
    color: 'white', // 選択状態の色
  },
});
// 完了モーダルのスタイル
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
// 画像リストアイテム用のスタイル
const ImageListItemStyle = {
  position: 'relative',
    '&:hover': {
      '& .image-checkbox': {
      display: 'flex', // ホバー時にチェックボックスを表示
      },
    },
  };
  
  // チェックボックス用のスタイル
  const CheckboxStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '8px', // 余白を設定
    // display: 'none', // 初期状態では非表示
    backgroundColor: 'rgba(255,255,255,0.7)', // 背景を半透明に
    borderRadius: '4px', // 角を丸く
    display: 'flex', // 常に表示
    // '&.image-checkbox': {
    //   display: 'none', // 初期状態では非表示
    // },
  };


// Props の型定義を追加
type Step3FormProps = {
  inputKeyword: string;
  setInputKeyword: (keyword: string) => void;
  onSubmitKeyword: () => void;
  article:Article | null;
  setArticle: (article: Article | null) => void;
  suggestions:  string[]; // suggestions 配列の型を Suggestion 型の配列として追加
  isLoadingArticle: boolean;
  // rewriteArticle: (option: string, selectedText: string) => void; // リライト処理のための関数を追加
  imageData: ImageData[];
  // postArticle: (article:Article, imageUrl: string,blog:Blog) => Promise<void>;
  enKey: string;
  selectedImage: ImageData|null;
  setSelectedImage: (image:ImageData) => void;
  articleHTML:string;
  selectedBlog: Blog|null;
  setSelectedBlog: (blog:Blog)=> void;
  selectedImages: ImageData[];
  setSelectedImages: (images: ImageData[]) => void;
  setArticleHTML: (articleHTML:string)=> void;
  suggestArticle: () => void;
  values: any;
  isLoadingCrawling:boolean;
  setIsLoadingCrawling:(flag:boolean)=> void;
};

const Step3Form: React.FC<Step3FormProps>  = ({ inputKeyword, setInputKeyword, onSubmitKeyword ,suggestions,isLoadingArticle,
  article,setArticle,imageData,enKey,
  selectedImage,setSelectedImage,articleHTML,
  setSelectedBlog,selectedBlog,setSelectedImages,
  selectedImages,setArticleHTML,values,suggestArticle,isLoadingCrawling
}) => {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedText, setSelectedText] = useState<string>("");
  const { images, isLoading: isLoadingImages } = useFetchImages(values.enKey);
  const { user, userId } = useAppContext();
  const [selectedBlogId, setSelectedBlogId] = useState<string>('');
  // モーダル表示制御用ステート
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(articleHTML);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [articleHTML]);
  useEffect(() => {
    if (article && selectedImages.length > 0) {
      // selectedImagesが更新されたときに記事のHTMLを更新する
      const updatedHTML = createHtmlWithImage(article, selectedImages);
      setArticleHTML(updatedHTML.html);
    }
  }, [selectedImages, article]); // 依存配列にselectedImagesとarticleを追加
  useEffect(() => {
    if (images.length > 0) {
      // 最初の4つの画像を選択状態として設定
      const index = Math.floor(Math.random() * 90);
      const initialSelectedImages = images.slice(index, index+5);
      setSelectedImages(initialSelectedImages);
    }
  }, [images]); // 依存配列に images と setSelectedImages を追加
  

  useEffect(() => {
    // ブログデータ取得
    if (user) {
      const fetchBlogs = async () => {
        const blogCollectionRef = collection(db, "blogs");
        const q = query(
          blogCollectionRef,
          where("userId", "==", userId),
          orderBy("createdAt")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newBlogs: Blog[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            url: doc.data().url,
            title: doc.data().title,
            entryId: doc.data().entryId,
            entryPw: doc.data().entryPw,
            draftFlag: doc.data().draftFlag,
            createdAt: doc.data().createdAt,
          }));
          setBlogs(newBlogs);
        });
  
        return () => {
          unsubscribe();
        };
      };
  
      fetchBlogs();
    }
  }, [userId, user]); // 依存配列

  const handleBlogChange = (event: SelectChangeEvent<string>) => {
    // ここでは `as string` を使って `event.target.value` の型を明示的に指定
    const blogId = event.target.value as string;
    setSelectedBlogId(blogId);
  
    // 選択されたブログオブジェクトを検索して、親コンポーネントの状態を更新
    const selectedBlog = blogs.find(blog => blog.id === blogId);
    if (selectedBlog) {
      setSelectedBlog(selectedBlog);
    }
  };

  // EditorState が変更されたときの処理
  const onEditorStateChange = (newState:EditorState) => {
    setEditorState(newState);
  };

  // console.log(images);
  const handleMouseOver = (text:string) => {
    setSelectedText(text);
    setOpenModal(true);
  };

  

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const { t } = useTranslation();
  // マウスアップイベントハンドラを追加
  const handleMouseUp = () => {
    const selectedText = window.getSelection()?.toString();
    if (selectedText && selectedText.trim().length > 0) {
      // 選択されたテキストがあり、空白でない場合にモーダルを開く
      setSelectedText(selectedText);
      setOpenModal(true);
    }
  };


  const  handlepostArticle = (article:Article | null) => {
    setArticle(article);
    console.log(selectedBlog);
    console.log(selectedImage);
    // ここでリライトロジックを実装
    if (!article || !selectedImage || !selectedBlog) return; // selectedBlog が null でないこともチェック

    postArticle(article,selectedImage,selectedBlog);
    setOpenModal(false);
  };
  
  const handleRegenerateArticle = async () => {
    // ここに再生成のロジックを実装
    // 例: API を呼び出して新しい記事データを取得する
    try {
      await suggestArticle(); // 仮のAPIコール関数
      // setArticle(newArticle); // 状態を更新して新しい記事をセット
      // 必要に応じて他の状態も更新
    } catch (error) {
      console.error("記事の再生成に失敗しました: ", error);
    }
  };


// HTML文字列をクリップボードにコピーする関数
const handleCopyAndPaste = async (article: Article | null) => {
  if (!article ) return;

  const html = createHtmlWithImage(article, selectedImages);
  
  try {
    // クリップボードにHTMLをコピー
    await navigator.clipboard.writeText(html.html);
    // 処理成功時にモーダル表示
    setShowModal(true);
  } catch (err) {
    console.error('failed', err);
  }
};
  return (
    <>
  {/* <Grid container spacing={10}
      direction="row"
      justifyContent="center"
      // alignItems="center"
      > */}
      <Grid container spacing={2} direction="column">
    <Grid item  xs={12} lg={12}>

    <FormGroup>
        <Typography variant="h5">
        {t('review_rewrite_generated_article')}
        </Typography>
      {isLoadingImages ? (
            <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            イメージの読み込み<CircularProgress />
            </Box>
          ) : (
            <Box sx={{ overflowX: 'auto' }}>
        {/* <RadioGroup
          name="image-select"
          value={selectedImage ? selectedImage.previewURL : ''}
          onChange={handleImageChange}
        > */}
        <ImageList sx={{ flexWrap: 'nowrap', transform: 'translateZ(0)', width: 'auto', height: 164 }} cols={10} rowHeight={164}>
          {images.map((image, index) => (
            <ImageListItem key={index} sx={ImageListItemStyle}>
              <img
                src={image.mediaURL}
                alt={image.tags}
                loading="lazy"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        {/* </RadioGroup> */}
        </Box>
        
        )}
        { isLoadingCrawling && (
            // <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            // <CircularProgress />
            // </Box>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingCrawling}
          >
            関連サイトの読み込み<CircularProgress color="inherit" />
          </Backdrop>
        )}
        { isLoadingArticle && (
            // <Box component="section"  sx={{ my:20,alignItems: 'center',textAlign:'center'}}>
            // <CircularProgress />
            // </Box>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingArticle }
          >
            記事の作成<CircularProgress color="inherit" />
          </Backdrop>
        )}
        {/* { article.length > 0 && ( */}
        <div className="App">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>

          <Button variant="contained" color="primary" size="small" onClick={() => handleCopyAndPaste(article)}>
            {t('copy_paste')}
          </Button>
          <Button variant="contained" color="secondary" size="small" onClick={handleRegenerateArticle} >
          {t('regenerate')}
          </Button>
        </Box>
      <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        />
        </div>
      </FormGroup>
    {/* </Container> */}
    </Grid>
    <Grid item xs={12} lg={12}>
    {/* <Typography variant="h5">
          画像ー一覧
        </Typography> */}
      {blogs.length > 0 ? (
      <Box component="section" sx={{ p: 5,marginBottom:5,marginTop:5,border: '1px dashed grey',width: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="blog-select-label">投稿ブログ</InputLabel>
            <Select
              labelId="blog-select-label"
              id="blog-select"
              value={selectedBlogId}
              label={t('blog')}
              onChange={handleBlogChange}
              style={{  maxWidth: '100%' }}
            >
              {blogs.map(blog => (
                <MenuItem key={blog.id} value={blog.id}>{blog.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
            <Button variant="contained" color="primary"  size="small" fullWidth onClick={() => handlepostArticle(article)}>
                {t('post')}
            </Button>

          </Box>
      </Box>
          ) : (
            <Box component="section" sx={{ p: 5, marginBottom: 5, marginTop: 5, border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {t('no_blogs_to_post')}
              <Link href="/blog/new" variant="body2">
              {t('register_your_blog')}
              </Link>
            </Box>
        )}
    </Grid>
  </Grid>


        {/* 完了モーダル */}
        <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Complete
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => setShowModal(false)}>close</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Step3Form;

