import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { useTranslation } from 'react-i18next';

const Unsubscribe = () => {
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleUnsubscribe = async () => {
    if (!auth.currentUser) {
      console.error('ユーザーがログインしていません。');
      return;
    }

    try {
      await auth.currentUser.delete();
      setUnsubscribeSuccess(true);
    } catch (error) {
      console.error('アカウントの削除中にエラーが発生しました:', error);
    }
  };

  const handleGoBack = () => {
    navigate('/'); // ユーザーをトップページにリダイレクト
  };

  return (
    <div>
      <h2>退会ページ</h2>
      {!unsubscribeSuccess ? (
        <>
          <p>本当に退会しますか？</p>
          <button onClick={handleUnsubscribe}>退会する</button>
        </>
      ) : (
        <>
          <p>アカウントが正常に削除されました。</p>
          <button onClick={handleGoBack}>トップページに戻る</button>
        </>
      )}
    </div>
  );
};

export default Unsubscribe;
