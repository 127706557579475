// src/hooks/useTemplate.tsx
import { useState, useEffect } from 'react';

const useTemplate = (path:string) => {
  const [template, setTemplate] = useState('');

  useEffect(() => {
    fetch(path)
      .then(response => response.text())
      .then(text => setTemplate(text));
  }, [path]);

  return template;
};

export default useTemplate;
