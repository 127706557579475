import {
  Home as HomeIcon,
  Photo as PhotoIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {
  NavigationItem,
  NavigationSingleItem,
  NavigationGroupItem
} from "../NavigationList";

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
const createNavigationSingleItem = (item: NavigationSingleItem) => item;
const createNavigationGroupItem = (item: NavigationGroupItem) => item;

export const NAVIGATION_ITEMS: NavigationItem[] = [

  createNavigationSingleItem({
    icon: LibraryBooksIcon,
    title: 'Articles',
    href: "/articles"
  }),
  createNavigationSingleItem({
    title: 'Posts',
    icon: AutoStoriesIcon,
    href: "/blogs"
  }),
  createNavigationGroupItem({
    icon: SettingsIcon,
    title: 'Setting',
    subs: [
      createNavigationSingleItem({
        title:  'Password',
        href: "auth/forgot-password"
      }),
      createNavigationSingleItem({
        title: 'Plan',
        href: "/settings/plan"
      }),
    ]
  }),
  createNavigationGroupItem({
    icon: PersonIcon,
    title: "About",
    subs: [

      createNavigationSingleItem({
        title: "Rule",
        href: "rule/term-of-service"
      }),
      createNavigationSingleItem({
        title: "PrivacyPolicy",
        href: "/rule/privacy-policy"
      }),
      createNavigationSingleItem({
        title: "Manual",
        href: "/lp"
      }),
    ]
  }),
];
