// LanguageSwitcher.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string);
  };
  return (
    <FormControl style={{ margin: 1,  minWidth: 70 }}>
    <Select
      value={i18n.language}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      // sx={{ backgroundColor: 'white', ".MuiOutlinedInput-notchedOutline": { borderColor: 'gray' } }}
    >
        {/* <MenuItem value="en"><span role="img" aria-label="USA">🇺🇸</span> English</MenuItem>
        <MenuItem value="ja"><span role="img" aria-label="Japan">🇯🇵</span> 日本語</MenuItem>
        <MenuItem value="zh"><span role="img" aria-label="China">🇨🇳</span> 中文</MenuItem>
        <MenuItem value="es"><span role="img" aria-label="Español">🇪🇸</span> Español</MenuItem>
        <MenuItem value="ar"><span role="img" aria-label="Arabic">🇸🇦</span> العربية</MenuItem>
        <MenuItem value="fr"><span role="img" aria-label="France">🇫🇷</span> Français</MenuItem>
        <MenuItem value="pt"><span role="img" aria-label="Portugal">🇵🇹</span> Português</MenuItem>
        <MenuItem value="ko"><span role="img" aria-label="South Korea">🇰🇷</span> 한국어</MenuItem>
        <MenuItem value="id"><span role="img" aria-label="Indonesia">🇮🇩</span> Bahasa Indonesia</MenuItem>
        <MenuItem value="hi"><span role="img" aria-label="India">🇮🇳</span> हिन्दी</MenuItem>
        <MenuItem value="de"><span role="img" aria-label="Germany">🇩🇪</span> Deutsch</MenuItem> */}
        <MenuItem value="en"><span role="img" aria-label="USA">🇺🇸</span></MenuItem>
        <MenuItem value="ja"><span role="img" aria-label="Japan">🇯🇵</span></MenuItem>
        <MenuItem value="zh"><span role="img" aria-label="China">🇨🇳</span></MenuItem>
        <MenuItem value="es"><span role="img" aria-label="Español">🇪🇸</span></MenuItem>
        <MenuItem value="ar"><span role="img" aria-label="Arabic">🇸🇦</span></MenuItem>
        <MenuItem value="fr"><span role="img" aria-label="France">🇫🇷</span></MenuItem>
        <MenuItem value="pt"><span role="img" aria-label="Portugal">🇵🇹</span></MenuItem>
        <MenuItem value="ko"><span role="img" aria-label="South Korea">🇰🇷</span></MenuItem>
        <MenuItem value="id"><span role="img" aria-label="Indonesia">🇮🇩</span></MenuItem>
        <MenuItem value="hi"><span role="img" aria-label="India">🇮🇳</span></MenuItem>
        <MenuItem value="de"><span role="img" aria-label="Germany">🇩🇪</span></MenuItem>    
    </Select>
  </FormControl>
  );
}

export default LanguageSwitcher;
