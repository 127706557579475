import { useState, useEffect } from 'react';

// Define your ImageData type if it's not imported
type ImageData = {
    mediaURL: string;
    tags: string;
    colorSpace?: string;  // Optional if not always available
    data?: Uint8ClampedArray;  // Optional, for actual image data if necessary
    height?: number;  // Optional, specify if needed
    width?: number;  // Optional, specify if needed
};

const useFetchImages = (enKey: string) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchImages = async (query: string, isCategory: boolean = false) => {
      setIsLoading(true);
      try {
        const url = new URL('https://wb1wp5h089.execute-api.ap-northeast-1.amazonaws.com/dev/pixabay-api');
        if (isCategory) {
          const categories = ['backgrounds', 'fashion', 'nature', 'science', 'education', 'feelings', 'health', 'people', 'religion', 'places', 'animals', 'industry', 'computer', 'food', 'sports', 'transportation', 'travel', 'buildings', 'business', 'music'];
          const randomIndex = Math.floor(Math.random() * categories.length);
          query = categories[randomIndex];
        }
        const params = new URLSearchParams({
          [isCategory ? 'category' : 'q']: query,
          orientation: 'horizontal',
          video: 'false'
        });
        url.search = params.toString();
        
        const response = await fetch(url.toString());
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data && data.mediaURL) {
          setImages(prevImages => [...prevImages, {
            mediaURL: data.mediaURL,
            tags: data.tags,
            // Add default values or handle dynamically as needed
            colorSpace: 'RGB',
            data: new Uint8ClampedArray(), // Example placeholder
            height: 100,
            width: 100
          } as ImageData]);
        } else {
          console.error('Invalid format of fetched data', data);
        }
      } catch (error) {
        console.error('Failed to fetch images', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (enKey) fetchImages(enKey);
  }, [enKey]);

  return { images, isLoading };
};

export default useFetchImages;
