
import axios from 'axios';
import { Site, Outline,Article,Blog,ImageData,ArticleCreationData } from '../types';
import { createHtmlWithImage,generateArticleId } from "../utils/articleUtils"; // 適切なパスに修正してください
import { Firestore, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import React from 'react';



export const postArticle = async (article: Article, selectedImage: ImageData, selectedBlog: Blog) => {



  if (!selectedBlog) return;
  const wordpressImageUrl = ""; // ここに画像アップロードロジックを実装

  const apiEndpoint = "https://o06j2dooq9.execute-api.ap-northeast-1.amazonaws.com/dev/wordpress-newpost-api";
  const htmlContent = createHtmlWithImage(article, selectedImage);
  try {
    const response = await axios.post(apiEndpoint, {
      url: selectedBlog.url,
      title: article.title,
      username: selectedBlog.entryId,
      password: selectedBlog.entryPw,
      draftFlag: selectedBlog.draftFlag,
      content: htmlContent
    });
  
    console.log("APIへの投稿成功:", response.data);
    // 成功後の処理をここに追加
  } catch (error) {
    console.error("APIへの投稿失敗:", error);
  }
};


export const researchCompetitorSite = async (inputKeyword:string) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const handleOpenModal = (message:string) => {
    setModalMessage(message);
    setOpenModal(true);
  };
  if (inputKeyword.trim()) {
    try {
      const response = await axios.get(`https://o06j2dooq9.execute-api.ap-northeast-1.amazonaws.com/dev/crawlee?q=${encodeURIComponent(inputKeyword)}`);
      const sites = response.data.items;
      console.log(sites);
      const extractedData = sites.map((site:Site) => ({
        title: site.title,
        link: site.link,
        snippet: site.snippet,
        image: site.pagemap?.cse_image?.[0]?.src, // サムネイル画像のURL
      }));
      return extractedData;
    } catch (error) {
      console.error(error);
      handleOpenModal('取得に失敗しました。再度「競合サイトの調査」をクリックしてください。');
      return []; // エラーが発生した場合は空の配列を返す
    }
  }
  return []; // キーワードが空の場合は空の配列を返す
};


export const saveOrUpdateArticleWithCustomId = async (db: Firestore, userId: string | null, articleData: ArticleCreationData) => {
  if(!userId) return;
  const articleId = generateArticleId(userId, articleData.selectedTitle);
  const docRef = doc(db, "articles", articleId);

  try {
    await setDoc(docRef, {
      ...articleData,
      userId: userId,
      createdAt: serverTimestamp(),
    }, { merge: true });
    console.log("Document saved or updated with custom ID: ", articleId);
  } catch (error) {
    console.error("Error saving or updating document: ", error);
  }
};