import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Layout } from "./layouts/Layout";

import { TopPage } from "./pages/TopPage";
import { ArticleListPage } from "./pages/ArticleListPage";
import { ArticleNewPage } from "./pages/ArticleNewPage";
import { BlogListPage } from "./pages/BlogListPage";
import { BlogNewPage } from "./pages/BlogNewPage";
import { SettingPlanPage } from "./pages/SettingPlanPage";

// import { SettingPasswordPage } from "./pages/SettingPasswordPage";
import { SettingNotificationDesktopPage } from "./pages/SettingNotificationDesktopPage";
import { SettingNotificationEmailPage } from "./pages/SettingNotificationEmailPage";
import ForgotPassword from './pages/ForgotPassword';
import {Login} from './pages/Login';
import Register from './pages/Register';
import Unsubscribe from './pages/Unsubscribe';
import {PrivacyPolicyPage} from './pages/PrivacyPolicy';
import {TermOfServicePage} from './pages/TermOfService';
export const AppRoutes: FC = () => {


  // const supportedLangs = ['en', 'ja', 'zh','es'];

  // const getDefaultLang = () => {
  //   const browserLang = navigator.language.split('-')[0]; // ブラウザの言語設定を取得し、地域コードを除外
  //   if (supportedLangs.includes(browserLang)) {
  //     return browserLang; // サポートされている言語の場合は、その言語を返す
  //   }
  //   return 'ja'; // サポートされていない言語の場合は、デフォルトで'ja'を返す
  // };

  return (
    // <Routes>
    //   <Route path="/" element={<Navigate replace to={`/${getDefaultLang()}/`} />} /> 
    //    <Route  path="/:lang" element={<Layout />}>
    //     <Route path="/" element={<TopPage />} />
    //     <Route path="/articles" element={<ArticleListPage />} />
    //     <Route path="/article/new" element={<TopPage />} />
    //     <Route path="/blogs" element={<BlogListPage />} />
    //     <Route path="/blog/new" element={<BlogNewPage />} />
    //     <Route path="/settings/plan" element={<SettingPlanPage />} />
    //     <Route path="/auth/login" element={<Login />} />
    //     <Route path="/auth/register" element={<Register />} />
    //     <Route path="/auth/forgot-password" element={<ForgotPassword />} />
    //     <Route path="/auth/unsubscribe" element={<Unsubscribe />} />
    //     <Route path="/rule/privacy-policy" element={<PrivacyPolicyPage />} />
    //     <Route path="/rule/term-of-service" element={<TermOfServicePage />} />
    //   </Route>
      // </Routes>
      <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<TopPage />} />
        <Route path="/articles" element={<ArticleListPage />} />
        <Route path="/article/new" element={<TopPage />} />
        <Route path="/blogs" element={<BlogListPage />} />
        <Route path="/blog/new" element={<BlogNewPage />} />
        <Route path="/settings/plan" element={<SettingPlanPage />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/unsubscribe" element={<Unsubscribe />} />
        <Route path="/rule/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/rule/term-of-service" element={<TermOfServicePage />} />
      </Route>
    </Routes> 

  );
};
